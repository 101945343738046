import numeral from 'numeral';
import 'numeral/locales/pt-br';

export function maskMoney(number: number) {
  numeral.locale('en');
  let value = numeral(number ? number : 0.0).value();

  numeral.locale('pt-br');
  return numeral(value).format('$ 0,0.00');
}

export function numberFormat(value: any) {
  let number = value.toString().replace(/[^0-9]/g, '');
  number = (number / 100).toFixed(2).replace('.', ',').split('').reverse().map((v, i) => i > 5 && (i + 6) % 3 === 0 ? `${v}.` : v).reverse().join('');
  return number
}

export const setStorange = (name: string, value: string) => {
  sessionStorage.setItem(name, value);
  return getStorange(name);
}

export const getStorange = (name: string) => {
  return sessionStorage.getItem(name);
}

export const inputMask = (value: string, element: string) => {
  let data = value.replace(/\.|-|\/|\s|\(|\)/g, '');

  if (element === 'cep') {
    data = data.replace(/^(\d{2})(\d{3})(\d{3})/g, '$1$2-$3')
  }

  if (element === 'telefone') {
    if (data.length <= 11) {
      data = data.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, '($1) $2-$3')
    }
  }

  if (element === 'cpf') {
    data = data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  if (element === 'cnpj') {
    data = data.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }

  return data;
}

export const pesquisaCep = async (value: string) => {
  let cep = value.replace(/[^0-9]/g, '');
  const rest = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
  return rest;
}

export const encondeBase64 = (data: string): String => {
  return '';
}

export const decondeBase64 = (data: string): String => {
  return '';
}

export const getArrayFromResponse = (data: Array<any>) => {
  var index = [];
  var length = Object.keys(data).length;
  if (length > 0) {
    for (var i = 1; i <= length; i++) {
      index.push(data[i]);
    }
  }
  return index;
}

export const isNullOrEmpty = (value: string | null | undefined) =>{
  return isNull(value) || value == '';
}

export const isNull = (value: any) =>{
  return value == null || value == undefined;
}