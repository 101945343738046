import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './home';

const Views: React.FC = () => {

  return (
    <BrowserRouter>    
      <Switch>
        <Route exact path="/:live" component={Home} />
        <Route exact path="/:live/:tokenId?" component={Home} />
      </Switch>
    </BrowserRouter>
  )
}

export default Views;