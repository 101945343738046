export const ENVIRMENT_PROD = true;
export const URL_PROD = "https://meepserver.azurewebsites.net/api/";
export const URL_LOCAL = "http://localhost/Promptus.Meep.Server/api/";
export const URL_HOMO = "https://meepserver-dev.azurewebsites.net/api/";


export const TOKEN = "sadfgrth52t432fewrthy5364y3qewvrbety5j7"

export const URL_MEPAY_PROD = "https://mepay-prod.azurewebsites.net/";
// export const URL_MEPAY_PROD = "http://192.168.0.20:3001/"


export const HEADER = {
  'Content-Type': 'application/json'
}