import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import GlobalStyle from "../../assets/styles/global";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { Pergunta, Instituicao } from "../../shared/types";

import perguntasJson from "../../resources/perguntas.json";

import {
  FaCaretDown,
  FaMinus,
  FaExclamationCircle,
  FaCheckCircle,
  FaTimes,
} from "react-icons/fa";

import { api } from "../../shared/webService";

import Doacoes from "../../components/doacoes";
import Banner from "../../assets/images/web.jpg";
import Mobile from "../../assets/images/mobile.jpg";

import { Modal, Button } from "react-bootstrap";
import { ResumoPedido } from "../../interfaces/resumoPedito";

import {
  ENVIRMENT_PROD,
  URL_MEPAY_PROD,
} from "../../shared/constantes";
import { isNullOrEmpty } from "../../shared/functions";

const Home: React.FC = () => {
  let perguntaRef = useRef<HTMLDivElement[]>([]);
  const mePayUrl = URL_MEPAY_PROD;


  const [modal, setModal] = useState(false);
  const [bannerWeb, setBannerWeb] = useState(Banner);
  const [bannerMobile, setBannerMobile] = useState(Mobile);

  const [perguntas, setPerguntas] = useState<Pergunta[]>();
  const [instituicoes, setInstituicoes] = useState<Instituicao[]>();
  const [eventoNome, setEventoNome] = useState<string>("");
  const [instituicao, setInstituicao] = useState<number>(0);
  const [meepId, setMepId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [showValueAbove, setShowValueAbove] = useState<boolean>(false);
  const [frequentlyQuestion, setFrequentlyQuestion] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [mePaydURL, setMepayUrl] = useState<string>("");
  const iFrame = useRef<HTMLIFrameElement>(null);
  const [idDoacao, setIdDoacao] = useState<string>("");
  const [resumoPedido, setResumoPedido] = useState<ResumoPedido>();
  const [mensagemErro, setMensagemErro] = useState<string>();
  const [aboutProjectHtml, setAboutProjectHtml] = useState<string>("");
  const [detailHtml, setDetailHtml] = useState<string>("");
  const [whichInstitution, setWhichInstitution] = useState<string>("");
  const [labelButtonDonate, setLabelButtonDonate] = useState<string>("");
  const [contactPhrase, setContactPhrase] = useState<string>("");
  const [contactPhone, setContactPhone] = useState<string>("");
  const [colorTheme, setColorTheme] = useState<string>("#C00D1E");
  const [customThanks, setCustomThanks] = useState<string>("");

  const { tokenId, live } = useParams<{ tokenId?: string, live?: string }>()



  const openRespostas = (posicao: number) => {
    const elementeTitulo = perguntaRef.current[posicao]
      .firstChild as HTMLDivElement;
    const elementResposta = perguntaRef.current[posicao]
      .lastChild as HTMLDivElement;

    const caretDown = elementeTitulo.firstChild?.lastChild
      ?.firstChild as SVGElement;
    const minus = elementeTitulo.firstChild?.lastChild?.lastChild as SVGElement;

    //atribui ao elemento displey o atributo none para todos os itens
    perguntaRef.current.map((item: HTMLDivElement) => {
      if (item) {
        let elementeTitulo = item.firstChild as HTMLDivElement;
        let elementResposta = item.lastChild as HTMLDivElement;

        elementResposta.style.display = "none";

        let caretDown = elementeTitulo.firstChild?.lastChild
          ?.firstChild as SVGElement;
        let minus = elementeTitulo.firstChild?.lastChild
          ?.lastChild as SVGElement;

        caretDown.style.display = "none";
        minus.style.display = "block";
        minus.style.float = "right";

        return true;
      } else {
        return false;
      }
    });

    //abre o elemento específico
    elementResposta.style.display = "block";
    caretDown.style.display = "block";
    caretDown.style.float = "right";
    minus.style.display = "none";
  };

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, []);

  const messageHandler = (event: MessageEvent) => {
    if (event.data.sucesso !== undefined) {
      if (event.data.sucesso) {
        setOpen(false);
        setIdDoacao(event.data.idPedido);
        setOpenMessage(true);
      } else {
        setMensagemErro(event.data.erro);
        setModal(true);
      }
    }
  };

  function exibirMePay(descricao: string, valor: number, produtoId: string) {
    if (tokenId) {
      setMepayUrl(mePayUrl + meepId + "/" + tokenId ?? "");
    } else {
      setMepayUrl(mePayUrl + meepId + "/");
    }
    const pedido = obterDadosPedido(descricao, valor, produtoId);
    setResumoPedido(pedido);
    setOpen(true);
  }

  function onLoadIFrame() {
    console.log([resumoPedido, mePaydURL]);
    const timer = setTimeout(() => {
      iFrame.current?.contentWindow?.postMessage(resumoPedido, mePaydURL);
    }, 1000);
  }

  function obterDadosPedido(
    descricao: string,
    valor: number,
    produtoId: string
  ): ResumoPedido {
    const pedido: ResumoPedido = {
      idReferencia: "",
      valorPedido: valor,
      itens: [
        {
          produtoId: produtoId,
          nome: descricao,
          quantidade: 1,
          opcoesDoConsumidor: "",
          valorUnitario: valor,
        },
      ],
    };

    return pedido;
  }

  const getInitData = useCallback(() => {
    api(`Event/${live}`).then((res) => {
      if (res.instituicoes) {
        if (res.localClienteId) {
          setMepId(res.localClienteId);
        }


        setInstituicoes(res.instituicoes);
        setEventoNome(res.nome);

        if (isNullOrEmpty(res.colorTheme)) {
          setColorTheme("#C00D1E");
        } else {
          setColorTheme(res.colorTheme);
        }

        setShowValueAbove(res.showValueAbove);

        setCustomThanks(res.customThanks);

        if (res.aboutProjectHtml) setAboutProjectHtml(res.aboutProjectHtml);
        if (res.detailHtml) setDetailHtml(res.detailHtml);

        if (res.frequentlyQuestion) setFrequentlyQuestion(res.frequentlyQuestion);
        if (res.whichInstitution) setWhichInstitution(res.whichInstitution);
        if (isNullOrEmpty(res.labelButtonDonate)) {
          setLabelButtonDonate('Doar');
        } else {
          setLabelButtonDonate(res.labelButtonDonate);
        }
        if (res.contactPhrase) setContactPhrase(res.contactPhrase);
        if (res.contactPhone) {
          setContactPhone(res.contactPhone);
        } else {
          setContactPhone('3199946706');
        }

        if (res.banner_web) setBannerWeb(res.banner_web);
        if (res.banner_mobile) setBannerMobile(res.banner_mobile);

      } else {
        setMensagemErro("Ops! Live não encontrada.");
        setModal(true);
      }
    });
  }, [live]);



  useEffect(() => {
    getInitData();
    setPerguntas(perguntasJson);
  }, [getInitData]);

  function createDetailHTML() {
    return { __html: detailHtml };
  }

  function createAboutHTML() {
    return { __html: aboutProjectHtml };
  }
  return (
    <>
      <GlobalStyle colorTheme={colorTheme} />
      <Header buttonLabel={labelButtonDonate} />

      <section id="banner">
        <div className="web">
          <img src={bannerWeb} className="d-block w-100" alt="Meep" />
        </div>

        <div className="mobile">
          <img src={bannerMobile} className="d-block w-100" alt="Meep" />
        </div>
      </section>


      {!isNullOrEmpty(detailHtml) ? (
        <>
          <section id="tempo-de-unir-forcas">
            <div className="container text-center">
              <div dangerouslySetInnerHTML={createDetailHTML()} />
            </div>
          </section>
        </>
      ) : (
          <>
            <section id="tempo-de-unir-forcas">
              <div className="container text-center">
                <h2>É tempo de unir forças para ajudar quem precisa</h2>
                <p>
                  Mesmo de casa, é possível ajudar e fazer outras pessoas felizes.
                A sua doação é a esperança de um mundo melhor para alguém.{" "}
                </p>
              </div>
            </section>

            <section id="como-fazer-doacao">
              <div className="container text-center">
                <h2>Como fazer a doação?</h2>
                <p>
                  Para doar, basta escolher uma das instituições abaixo e, em
                  seguida, clicar em um dos links de pagamento referente ao valor
                  desejado. Siga os passos para finalizar a sua doação.
              </p>
              </div>
            </section>
          </>
        )}

      <section id="para-qual-instituicao-doar">
        <div className="container">
          <h2 style={{ color: `${colorTheme}` }}>
            {
              !isNullOrEmpty(whichInstitution) ? whichInstitution : 'Para qual instituição você gostaria de doar?'
            }
          </h2>

          <div className="row mb-4">
            {instituicoes?.map((item, i) => (
              <div key={i} className="col">
                <div
                  className={`seleciona-instituicao ${instituicao === i ? " active " : ""
                    }`}
                  onClick={() => setInstituicao(i)}
                >
                  <>
                    <input
                      type="radio"
                      checked={instituicao === i}
                      onChange={() => setInstituicao(i)}
                      name="instituicao"
                    />
                    <p>{item.nome}</p>
                  </>
                </div>
              </div>
            ))}
          </div>

          {instituicoes?.map((item, i) => (
            <React.Fragment>
              <div
                key={i}
                className="row"
                hidden={instituicao === i ? false : true}
              >
                {item.doacoes.map((itemDoacoes, iDoacoes) => (
                  <Doacoes
                    buttonLabel={labelButtonDonate}
                    showValueAbove={showValueAbove}
                    handleExibirMePay={exibirMePay}
                    ultimo={item.doacoes.length - 1 === iDoacoes}
                    live={live}
                    instituicao={item.nome}
                    links={itemDoacoes.links}
                    descricao={itemDoacoes.descricao}
                    valor={itemDoacoes.valor}
                    produtoId={itemDoacoes.produtoId}
                    eventoNome={eventoNome}
                  />
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>

        <Modal centered show={open} backdrop="static">
          <FaTimes className="closeModal" onClick={() => setOpen(!open)} />
          <iframe
            width={"100%"}
            height={"700"}
            frameBorder={"0"}
            marginWidth={0}
            marginHeight={0}
            src={mePaydURL}
            ref={iFrame}
            onLoad={onLoadIFrame}
          />
        </Modal>

        <Modal
          backdrop="static"
          centered
          show={openMessage}
          onHide={() => setOpenMessage(!openMessage)}
        >
          <Modal.Body
            style={{ position: "relative", padding: "100px 40px 40px 40px" }}
          >
            <div
              style={{
                position: "absolute",
                left: "35%",
                top: "-60px",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "100px",
              }}
            >
              <FaCheckCircle style={{ fontSize: "6rem", color: "#1f734e" }} />
            </div>
            {!isNullOrEmpty(customThanks) ? (
              <p className="customThanks">{customThanks}</p>
            ) : (
                <p className="text-confirm-donate">
                  Doação efetuada com sucesso. <br /> Evento:{" "}
                  <span>{eventoNome}</span>{" "}
                  <p className="text-confirm-donateid">{idDoacao}</p>
                  <br />
                  <p>Obrigado pela contribuição!</p>
                </p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-danger btn-block btn-lg"
              style={{
                backgroundColor: "#1f734e",
                borderColor: "#1f734e",
                borderRadius: "30px",
              }}
              onClick={() => setOpenMessage(!openMessage)}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </section>

      {!isNullOrEmpty(aboutProjectHtml) ? (
        <>
          <section id="sobre-o-projeto">
            <div className="container text-center">
              <div dangerouslySetInnerHTML={createAboutHTML()} />
            </div>
          </section>
        </>
      ) : (
          <>
            <section id="sobre-o-projeto">
              <div className="container text-center">
                <h2>Sobre o projeto </h2>
                <p>
                  Em tempos de crise, estamos aqui para levar alegria a milhares
                  de lares por todo país, seja com a música ou por doações
                  arrecadadas. Estaremos juntos no combate ao novo Coronavírus e
                  não deixaremos ninguém para trás. Neste momento, contamos com a
                  sua solidariedade para amparar aqueles que mais precisam da sua
                ajuda.{" "}
                </p>
              </div>
            </section>
          </>
        )}

      {
        frequentlyQuestion && (
          <section id="perguntas-frequentes">
            <div className="container">
              <h2>Perguntas Frequentes</h2>
              <div className="lista-perguntas-frequentes mt-5">
                {perguntas?.map((item, i) => (
                  <div
                    key={i}
                    className="pergunta"
                    ref={(element) =>
                      perguntaRef.current.push(element as HTMLDivElement)
                    }
                  >
                    <div className="titulo" onClick={() => openRespostas(i)}>
                      <div className="row justify-content-between align-items-center">
                        <div className="col-8 col-sm-8">
                          <h3>{item.pergunta}</h3>
                        </div>
                        <div className="col-4 col-sm-4 text-right">
                          <FaCaretDown style={{ display: "none" }} />
                          <FaMinus />
                        </div>
                      </div>
                    </div>
                    <div
                      className="texto"
                      id={`texto-${i}`}
                      style={{ display: "none" }}
                    >
                      <p dangerouslySetInnerHTML={{ __html: item.resposta }}></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )
      }

      <section id="disponibilizar-plataforma">
        <div className="container text-center">
          <p className="text-contact">
            {
              !isNullOrEmpty(contactPhrase) ? contactPhrase : `Quer disponibilizar essa plataforma para arrecadar
             doações em outras lives ou instituições?`
            }
          </p>
          <a
            href={`https://api.whatsapp.com/send?phone=55${contactPhone}&text=Ol%C3%A1,%20quero%20saber%20mais!`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-default btn-lg btn-rounded-30 mt-3"
          >
            Entrar em Contato
          </a>
        </div>
      </section>

      <section id="empresa">
        <div className="container text-center">
          <p>
            <b>Meep - Soluções Inteligentes de Pagamento e Autoatendimento</b>
          </p>
          <p>
            Rua Tenente Brito Melo, 427 - 4º andar - Barro Preto, Belo Horizonte
            - MG, 30180-072
          </p>
        </div>
      </section>

      <Footer />

      <Modal
        backdrop="static"
        centered
        show={modal}
        onHide={() => setModal(!modal)}
      >
        <Modal.Body
          style={{ position: "relative", padding: "100px 40px 40px 40px" }}
        >
          <div
            style={{
              position: "absolute",
              left: "35%",
              top: "-60px",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "100px",
            }}
          >
            <FaExclamationCircle
              style={{ fontSize: "6rem", color: "#C00D1E" }}
            />
          </div>
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "lighter",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {mensagemErro}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger btn-block btn-lg"
            style={{
              backgroundColor: "#C00D1E",
              borderColor: "#C00D1E",
              borderRadius: "30px",
            }}
            onClick={() => setModal(!modal)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
