import { URL_PROD, HEADER, ENVIRMENT_PROD } from './constantes';
import moment from 'moment';

export const api = async (url: string, method = 'GET', params?: object) => {

  const baseUrl = URL_PROD;

  let status = false;
  let header = HEADER;

  let configInitial = {
    headers: header,
    method: method,
    body: method === 'POST' || method === 'PUT' ? JSON.stringify(params) : undefined
  };

  let request = new Request(`${baseUrl}${url}`, configInitial);

  return await fetch(request)
    .then(response => {

      if (!ENVIRMENT_PROD) {
        console.log(`REQUEST: ${moment().format('DD/MM/YYYY HH:mm')}`, request)
        console.log(`RESPONSE: ${moment().format('DD/MM/YYYY HH:mm')}`, response);
      }

      status = response.ok;

      return response.json();
    })
    .then(data => {

      if (!ENVIRMENT_PROD) {
        console.log(`BODY: ${moment().format('DD/MM/YYYY HH:mm')}`, data);
      }

      if (!status) throw data;

      return data;
    })
    .catch(exception => {
      return status;
    });
}