import { createGlobalStyle } from "styled-components";
var colorTheme = (p: GlobalProps) => p.colorTheme;

export interface GlobalProps {
  colorTheme: string;
}


export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html, body, #root{
    height: 100%;
    background-color: #FFF;
    font-weight: 300;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font-family: 'Poppins', sans-serif;
  }

  a{
    text-decoration: none;
    color: #FFF !important;
  }

  ul{
    list-style: none;
  }
  button{
    cursor: pointer;
  }
 
  .btn-default{
    background-color: ${colorTheme};
    border-color: ${colorTheme};
  }

  section#tempo-de-unir-forcas h2 {
    color: ${colorTheme};
  }
  section#como-fazer-doacao h2 {
    color: ${colorTheme};
  }
  section#para-qual-instituicao-doar h2 {
    color: ${colorTheme};
  }
  section#para-qual-instituicao-doar p a.texto-destaque-outro-valor {
    color: ${colorTheme};
  }
  section#para-qual-instituicao-doar div.seleciona-instituicao{
    border: 2px solid ${colorTheme};
  }
  section#para-qual-instituicao-doar div.active {
    background-color: ${colorTheme};
  }
  section#para-qual-instituicao-doar div.doacao{
    border: 2px solid ${colorTheme};
  }
  section#para-qual-instituicao-doar div.doacao div.valor h3{
    color:  ${colorTheme};
  }
  section#para-qual-instituicao-doar div.doacao div.links a.btn-link{
    color:  ${colorTheme};
  }
  section#contribua-de-outra-forma h2{
    color:  ${colorTheme};
  }
  section#sobre-o-projeto h2{
    color:  ${colorTheme};    
  }
  section#sobre-o-projeto .texto-destaque{
    color:  ${colorTheme};
  }
  section#em-tempo-de-baixa h2{
    color:  ${colorTheme};
  }
  section#perguntas-frequentes h2{
    color:  ${colorTheme};
  }
  section#perguntas-frequentes div.lista-perguntas-frequentes div.pergunta{
    border-bottom: 1px solid ${colorTheme};
  }
  
  section#perguntas-frequentes
  div.lista-perguntas-frequentes
  div.pergunta
  div.titulo
  svg {
    color:  ${colorTheme};
}
section#disponibilizar-plataforma p{
  color:  ${colorTheme};
}
.currencyformat {
  border: 1px solid ${colorTheme};

}
footer{
  background-color: ${colorTheme};
}
`;
