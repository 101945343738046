import React from 'react';

import { Link } from 'react-router-dom';

import Logo from '../../assets/images/meep-logomarca.png';

interface HeaderProps{
  buttonLabel: string
}
const Header: React.FC<HeaderProps> = ({buttonLabel}) => {
  return (
    <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6">
            <Link to="/">
              <img src={Logo} width="30%" className="img-fluid" alt="Meep Doações" />
            </Link>
          </div>
          <div className="col-6 text-right">
            <a href="#para-qual-instituicao-doar" rel="noopener" className="btn btn-default btn-large btn-rounded ">
              {buttonLabel}
          </a>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;