import React, { useState, useEffect } from "react";
import { default as NumberFormat } from "react-number-format";

import { InstituicaoDocaoesLinks } from "../../shared/types";

import { maskMoney } from "../../shared/functions";

interface Props {
  buttonLabel: string
  instituicao?: string;
  live?: string;
  valor: number;
  descricao?: string;
  produtoId: string;
  links?: InstituicaoDocaoesLinks[];
  ultimo?: boolean;
  showValueAbove: boolean;
  eventoNome?: string;
  handleExibirMePay: (
    descricao: string,
    valor: number,
    produtoId: string
  ) => void;
}

const DoacaoLinkPagamento: React.FC<Props> = (data) => {
  const { valor, descricao, showValueAbove, handleExibirMePay, buttonLabel } = data;

  function exibirMePay() {
    data.handleExibirMePay(data.descricao ?? "", data.valor, data.produtoId);
  }

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="doacao">
        <div className="valor text-center">
          <h3 className="w-100">{maskMoney(valor)}</h3>
        </div>

        <div className="descricao">
          <p>{descricao}</p>
        </div>

        <div className="links">
          <a
            rel="noopener noreferrer"
            target="_blank"
            onClick={exibirMePay}
            className="btn btn-default btn-block btn-rounded"
          >
            {buttonLabel}
          </a>
        </div>
      </div>
    </div>
  );
};

const DoacaoLinkWhatsapp: React.FC<Props> = (data) => {
  function doarValorMaior() {
    if (isNaN(valorDocao)) {
      setValorDocaoErr(true);
      return;
    }

    if (valorDocao <= data.valor) {
      setValorDocaoErr(true);
      return;
    }
    setValorDocaoErr(false);

    data.handleExibirMePay(
      `${data.eventoNome} - ${data.instituicao}` ?? "",
      valorDocao,
      ""
    );
  }

  const { valor, descricao, buttonLabel } = data;
  const [valorDocao, setValorDocao] = useState<number>(0);
  const [valorDocaoErr, setValorDocaoErr] = useState<boolean>(false);

  useEffect(() => { setValorDocaoErr(false); }, [valorDocao])

  return (
    <>
      {
        data.showValueAbove && (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="doacao">
              <div className="valor text-center">
                <h3 className="w-100">Valor acima de {maskMoney(valor)}</h3>
              </div>
              <div className="descricao">
                <NumberFormat
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"R$ "}
                  className={valorDocaoErr ? "currencyformat currencyErr" : "currencyformat"}
                  placeholder="Digite o valor"
                  onValueChange={(values) => {
                    const { value } = values;
                    let valor = parseFloat(value);
                    setValorDocao(valor);
                  }}
                  decimalScale={2}
                />
              </div>
              <div className="links">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={doarValorMaior}
                  className="btn btn-default btn-block btn-rounded"
                >
                  {buttonLabel}
                </a>
              </div>{" "}
              {valorDocaoErr && (
                <div className="descricao">
                  <span className="spanCurrencyErr">
                    * O valor dever ser superior a {maskMoney(valor)}.
            </span>
                </div>
              )}
            </div>
          </div>
        )
      }
    </>
  );
};

const Doacoes: React.FC<Props> = (Props) => {
  const {
    valor,
    descricao,
    eventoNome,
    links,
    ultimo,
    instituicao,
    live,
    handleExibirMePay,
    produtoId,
    buttonLabel,
    showValueAbove
  } = Props;
  return (
    <>
      {!ultimo && (
        <DoacaoLinkPagamento
          showValueAbove={showValueAbove}
          handleExibirMePay={handleExibirMePay}
          instituicao={instituicao}
          descricao={descricao}
          produtoId={produtoId}
          links={links}
          valor={valor}
          buttonLabel={buttonLabel}
        />
      )}

      {ultimo && (
        <>
          <DoacaoLinkPagamento
            showValueAbove={showValueAbove}
            handleExibirMePay={handleExibirMePay}
            instituicao={instituicao}
            descricao={descricao}
            links={links}
            valor={valor}
            produtoId={""}
            buttonLabel={buttonLabel}
          />
          <DoacaoLinkWhatsapp
            showValueAbove={showValueAbove}
            handleExibirMePay={handleExibirMePay}
            instituicao={instituicao}
            live={live}
            ultimo={ultimo}
            descricao={descricao}
            links={links}
            valor={valor}
            produtoId={produtoId}
            eventoNome={eventoNome}
            buttonLabel={buttonLabel}
          />
        </>
      )}
    </>
  );
};

export default Doacoes;
