import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="copyright">
        <div className="container text-center">
          <p>Meep</p>
        </div>
      </div>
    </footer>
  )
}
export default Footer;