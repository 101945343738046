import React from "react";
import ReactDOM from "react-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";

import Views from "./views";

ReactDOM.render(
  <React.StrictMode>
    <Views />
  </React.StrictMode>,
  document.getElementById("root")
);
